export const useMenuDataStore = defineStore("menuData", () => {
  const initialized = ref(false);

  const { data, refresh } = useApi("/api/menuData", {
    immediate: false,
  });

  async function initialize() {
    if (!initialized.value) {
      initialized.value = true;
      await refresh();
    }
  }

  return {
    data,
    initialize,
    refresh,
  };
});
