export async function useMenuData() {
  const menuDataStore = useMenuDataStore();
  const { data } = storeToRefs(menuDataStore);
  const { initialize, refresh } = menuDataStore;

  await initialize();

  return {
    data,
    initialize,
    refresh,
  };
}
